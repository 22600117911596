<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton()"
    @edit-button="editButton()"
  >
    <v-text-field
      v-model="input.name"
      label="Имя"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.phone"
      v-mask="'+7##########'"
      label="Телефон"
      :rules="[$validate.required, $validate.phoneMin]"
    >
    </v-text-field>
    <v-select
      v-model="input.roleId"
      label="Роль"
      :items="roles"
      item-text="name"
      item-value="id"
      :rules="[$validate.required]"
      @change="getContracts($event)"
    >
    </v-select>
    <v-text-field
      v-if="input.roleId === 3"
      v-model="input.fuelCard"
      label="Топливная карта"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-select
      v-if="input.roleId === 3"
      v-model="input.contractTypeId"
      label="Тип контракта"
      :items="EMPLOYEE.contracts"
      item-text="name"
      item-value="id"
      :rules="[$validate.required]"
    >
    </v-select>
    <v-select
      v-model="input.departments"
      label="Филиалы"
      :items="resultDepartments"
      item-text="name"
      item-value="id"
      multiple
      :rules="[$validate.arrRequired]"
    >
    </v-select>
    <v-select
      v-model="input.timeTable"
      label="График"
      :items="TIMETABLE.timeShedule"
      item-text="name"
      item-value="id"
      :rules="[$validate.required]"
    >
    </v-select>
    <v-btn
      v-if="mode === 'Сохранить'"
      @click="
        showPassDialog = !showPassDialog;
        input.password = null;
      "
      >Изменить пароль</v-btn
    >
    <v-text-field
      v-if="editPassword"
      v-model="input.password"
      label="Пароль"
      :rules="[$validate.required, $validate.min]"
      type="password"
    >
    </v-text-field>
    <PasswordDialog
      :show="showPassDialog"
      :true-password="'985152M'"
      @close-modal="closePasswordModal($event)"
    />
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import PasswordDialog from "@/components/Order/PasswordDialog";
import employee from "@/model/employee";
import showMessage from "@/Functions/message";
import api from "@/api";
export default {
  components: { SingleComponent, PasswordDialog },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {
        name: "",
        phone: "",
        roleId: [],
        departments: [],
      },
      roles: [],
      departments: [],
      editPassword: true,
      showPassDialog: false,
      resultDepartments: [],
    };
  },
  computed: {
    ...mapGetters({
      EMPLOYEE: "Employee/STATE",
      DEPARTMENT: "Department/STATE",
      ROLE: "Role/STATE",
      USER: "User/STATE",
      CITY: "City/CITY",
      TIMETABLE: "Timetable/STATE",
    }),
  },
  async created() {
    let body = {};
    await this.getDepartment(this.$root.city);
    this.departments = this.DEPARTMENT.cityDepartment;
    await this.getRoles();
    this.roles = this.ROLE.allRoles;
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      body = await this.getEmployee(this.$route.params.id);
      this.editPassword = false;
    }
    let model = new employee();
    this.input = model.setInput(this.mode, body);
    if (!this.TIMETABLE.timeShedule.length) {
      this.GET_TIME_SHEDULE();
    }
    this.getContracts(this.input.roleId);
    this.getAllDepartmentFromCities();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      addEmployee: "Employee/ADD_EMPLOYEE",
      getEmployee: "Employee/GET_EMPLOYEE",
      editEmployee: "Employee/EDIT_EMPLOYEE",
      getDepartment: "Department/GET_DEPARTMENT",
      getRoles: "Role/GET_ALL_ROLES",
      GET_TIME_SHEDULE: "Timetable/GET_TIME_SHEDULE",
      GET_CONTRACTS: "Employee/GET_CONTRACTS",
    }),
    // получаем список контрактов если выбрали курьера, и если его нет в сторе
    getContracts(roleId) {
      if (!this.EMPLOYEE.contracts.length && roleId === 3) {
        this.GET_CONTRACTS();
      }
    },
    // создание нового сотрудника
    async addButton() {
      this.loading = true;
      let response = await this.addEmployee(this.input);
      if (response.type === "success") {
        this.$router.push("/employee");
      } else {
        showMessage(response.text);
      }
      this.loading = false;
    },
    // Получение всех филиалов, со всех городов
    async getAllDepartmentFromCities() {
      await this.CITY.forEach((el) => {
        let cityName = el.name;
        el.departments.forEach((el2) => {
          let departmentName = el2.name;
          let departmentId = el2.id;
          if (!el2.delete) {
            this.resultDepartments.push({
              id: departmentId,
              name: departmentName + " (" + cityName + ")",
            });
          }
        });
      });
    },
    async editButton() {
      this.loading = true;
      let response = await this.editEmployee(this.input);
      if (response.type === "error") {
        showMessage(response.text);
        this.loading = false;
        return;
      }
      const payload = {
        employeeId: this.$route.params.id,
        timeTableId: this.input.timeTable,
      };
      const respTime = await api.Timetable.assignTimeTable(payload);
      if (respTime.type === "error") {
        showMessage(respTime.data.message);
      } else {
        showMessage("Данные изменены", true);
      }
      this.$router.push("/employee");
      this.loading = false;
    },
    closePasswordModal(correctPass) {
      this.showPassDialog = false;
      if (!correctPass) {
        return;
      }
      this.editPassword = true;
    },
  },
};
</script>
